import React, { useContext, useEffect, useState } from "react";
import Styles from "./styles.module.scss";
import { Form, Input } from "antd";
import DeliveryHomeForm from "../../../forms/DeliveryHomeForm";
import DeliveryHomeList from "../../../components/DeliveryHome/DeliveryHomeList";
import { getAddresses } from "../../../helpers/API/requests/address";
import { AuthContext } from "../../../providers/AuthProvider";
import { TAddress } from "../../../helpers/API/requests/interfaces/address";
import Spinner from "../../../components/Spinner";
import { LocaleConsumer } from "../../../providers/LocaleProvider";
import Button from "../../../components/Button";
import { TUser } from "../../../providers/AuthProvider/interfaces";
import { setOrder } from "../../../helpers/API/requests/order";
import { CartContext } from "../../../providers/CartProvider";
import { useHistory } from "react-router-dom";
import ERoutes from "../../../routers/AuthRouter/routes";
import parseOrderErrorRedirect from "../../../helpers/parseOrderErrorRedirect";
import HTMLRender from "../../../components/HTMLRender";
import InputText from "../../../components/InputText";
import { EShippingAddressType } from "../../../helpers/API/requests/interfaces/order";
import InputSelect from "../../../components/InputSelect";
import { debounce } from "../../../helpers/debounce";

const DeliveryScreen: React.FC = () => {
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartContext);
  const history = useHistory();

  const isRep = authContext.user
    ? !!authContext.user.isManager || !!authContext.user.isRep || false
    : false;

  const [mode, setMode] = useState<"favorite" | "custom">("favorite");
  const [orderIsLoading, setOrderIsLoading] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<TAddress[] | undefined>(undefined);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [submitBtnIsDisabled, setSubmitBtnIsDisabled] = useState(false);
  const [shippingContactName, setShippingContactName] = useState<string>("");
  const [email, setEmail] = useState(isRep ? "" : authContext.user!.username!);
  const [extraInfo, setExtraInfo] = useState<string>("");
  const [selectedFavAddress, setSelectedFavAddress] = useState<
    TAddress | undefined
  >(undefined);

  useEffect(() => {
    if (mode === "favorite") {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, form]);

  useEffect(() => {
    getAddresses(authContext.user?.client?.accountCode!).then((data) => {
      setAddresses(data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitOrder = debounce(
    (
      address: TAddress,
      user: TUser,
      shippingContact: string,
      billingName?: string,
      phone?: string
    ) => {
      setSubmitBtnIsDisabled(true);
      setOrderIsLoading(true);
      setOrder(
        cartContext.items,
        address,
        {
          ...user,
          client: {
            ...user.client!,
            email: [email],
          },
        },
        shippingContact,
        mode === "favorite"
          ? EShippingAddressType.CLIENT_LIST
          : EShippingAddressType.ADDRESS,
        billingName,
        phone,
        extraInfo
      )
        .then(() => {
          setOrderIsLoading(false);
          cartContext.empty();
          history.push(ERoutes.OrderSuccess + "?type=home");
        })
        .catch((error) => {
          setOrderIsLoading(false);
          if (parseOrderErrorRedirect(error, history)) {
            cartContext.empty();
          }
        })
        .finally(() => {
          setSubmitBtnIsDisabled(false);
        });
    }
  );

  const submitFavoriteAddress = () => {
    if (mode === "favorite") {
      const client = authContext.user?.client;
      const selectAddress =
        selectedFavAddress ||
        (client &&
          ({
            clientCode: client.clientServiceCode,
            address1: client.street,
            address2: client.streetAddition,
            postalCode: client.postalCode,
            city: client.city,
            stateOrDepartment: client.stateOrDepartment,
            countryCode: client.countryCode,
            countryName: client.countryCodeName,
          } as TAddress));

      if (selectAddress) {
        submitOrder(
          selectAddress,
          authContext.user!,
          shippingContactName,
          authContext.user?.client?.accountName
        );
      }
    }
  };

  const onValidate = () => {
    if (mode !== "favorite") {
      form.submit();
    }
    form2.submit();
  };

  return (
    <LocaleConsumer>
      {({ getTranslation }) => (
        <div className={Styles["delivery"]}>
          <div className={Styles["delivery__titleLine"]} />
          <h1 className={`title ${Styles["delivery__title"]}`}>
            <HTMLRender>{getTranslation("order_page.rep.title")}</HTMLRender>
          </h1>
          <div className={Styles["delivery__container"]}>
            <p className={Styles["delivery_info"]}>
              {getTranslation("order_page.rep.delivery_info")}
            </p>
            {/* Removed initial Menu component from antd because it was causing too much display issue */}
            <div className={Styles.delivery__menu}>
              <button
                className={`
                  ${Styles["delivery__menuItem"]}
                  ${
                    mode === "favorite"
                      ? Styles["delivery__menuItem--favoriteSelected"]
                      : ""
                  }
                `}
                onClick={() => setMode("favorite")}
              >
                {getTranslation("order_page.favorite")}
              </button>
              <button
                className={`
                  ${Styles["delivery__menuItem"]} 
                  ${
                    mode === "custom"
                      ? Styles["delivery__menuItem--customSelected"]
                      : ""
                  }
                `}
                onClick={() => setMode("custom")}
              >
                {getTranslation("order_page.custom")}
              </button>
            </div>
            {mode === "favorite" ? (
              <>
                {addresses ? (
                  <DeliveryHomeList
                    addresses={addresses}
                    setSelectedAddress={setSelectedFavAddress}
                    selectedAddress={selectedFavAddress}
                  />
                ) : (
                  <Spinner />
                )}
              </>
            ) : (
              <DeliveryHomeForm formHook={form} onSubmit={submitOrder} />
            )}

            <Form form={form2} onFinish={submitFavoriteAddress} action="">
              {mode === "favorite" ? (
                <InputSelect
                  label={getTranslation("order_page.form.email")}
                  fieldName="email"
                  value={email}
                  setValue={setEmail}
                  readOnly={!isRep}
                  options={[
                    {
                      label: getTranslation("order_page.form.select_an_email"),
                      value: "",
                    },
                    ...(authContext.user?.client?.email?.map((_email) => ({
                      label: _email,
                      value: _email,
                    })) || []),
                  ]}
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "order_page.form.mandatory_field"
                      ),
                      validateTrigger: "onSubmit",
                    },
                    {
                      type: "email",
                      message: getTranslation("order_page.form.invalid_email"),
                      validateTrigger: "onBlur",
                    },
                    {
                      max: 60,
                      message: getTranslation("order_page.form.max_length", {
                        params: { max: 60 },
                      }),
                    },
                  ]}
                />
              ) : (
                <InputText
                  label={getTranslation("order_page.form.email")}
                  fieldName={"email"}
                  value={email}
                  setValue={setEmail}
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "order_page.form.mandatory_field"
                      ),
                      validateTrigger: "onSubmit",
                    },
                    {
                      type: "email",
                      message: getTranslation("order_page.form.invalid_email"),
                      validateTrigger: "onBlur",
                    },
                    {
                      max: 60,
                      message: getTranslation("order_page.form.max_length", {
                        params: { max: 60 },
                      }),
                    },
                  ]}
                />
              )}

              {mode === "favorite" && (
                <InputText
                  label={getTranslation("order_page.shipping_contact_name")}
                  fieldName={"shippingContactName"}
                  value={shippingContactName}
                  setValue={setShippingContactName}
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "order_page.form.mandatory_field"
                      ),
                    },
                    {
                      max: 36,
                      message: getTranslation("order_page.form.max_length", {
                        params: { max: 36 },
                      }),
                    },
                  ]}
                />
              )}
              <Input.TextArea
                placeholder={getTranslation("order_page.showroom_input")}
                maxLength={360}
                onChange={(e) => setExtraInfo(e.target.value)}
                rows={3}
              />
            </Form>
            {(mode !== "favorite" || addresses?.length !== 0) && (
              <Button
                type="button"
                styleType="plain"
                size="large"
                label={getTranslation("order_page.submit")}
                onClick={onValidate}
                className={Styles["delivery__submit"]}
                disabled={
                  orderIsLoading ||
                  submitBtnIsDisabled ||
                  (mode === "favorite" && (!email || !shippingContactName))
                }
                isLoading={orderIsLoading}
              />
            )}
          </div>
        </div>
      )}
    </LocaleConsumer>
  );
};

export default DeliveryScreen;
