import React from "react";

import { IButtonProps } from "./interfaces";
import Styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import Spinner from "../Spinner";

const Button: React.FC<IButtonProps> = ({
  styleType = "plain",
  size = "small",
  type = "button",
  onClick,
  label,
  link,
  className,
  disabled,
  isLoading,
}) => {
  let history = useHistory();
  const onButtonClick = async () => {
    await new Promise((r) => setTimeout(r, 150));
    if (onClick) {
      onClick();
    }
  };
  const onLinkClick = async () => {
    await new Promise((r) => setTimeout(r, 150));
    history.push(link as string);
  };
  return (
    <div className={`${Styles.container} ${className ? className : ""}`}>
      {link === undefined ? (
        <>
          <button
            type={type}
            className={`${Styles.button} ${Styles[`button__${styleType}`]} ${
              Styles[`button__${size}`]
            }`}
            onClick={onButtonClick}
            disabled={disabled}
          >
            <p style={{ opacity: isLoading ? 0 : 1 }}>{label}</p>
          </button>
          {isLoading ? (
            <div className={Styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : null}
        </>
      ) : (
        <button
          onClick={onLinkClick}
          className={`${Styles.button} ${Styles[`button__${styleType}`]} ${
            Styles[`button__${size}`]
          }`}
          disabled={disabled}
        >
          <p>{label}</p>
        </button>
      )}
    </div>
  );
};

export default Button;
