import React, { useContext } from "react";
import Styles from "./styles.module.scss";
import ERoutes from "../../../routers/NotAuthRouter/routes";
import { useHistory } from "react-router-dom";
import {
  LocaleConsumer,
  LocaleContext,
} from "../../../providers/LocaleProvider";
import Button from "../../../components/Button";
import BackgroundImageLayout from "../../../layouts/BackgroundImageLayout";
import backgroundImage from "../../../globals/assets/metier_papier_peint_gold.jpeg";
import LoginForm from "../../../forms/LoginForm";
import EGlobalRoutes from "../../../routers/AppRouter/routes";
import { getLogin, getUser } from "../../../helpers/API/requests/login";
import API from "../../../helpers/API";
import { AuthContext } from "../../../providers/AuthProvider";
import { getClientInfo } from "../../../helpers/API/requests/clients";
import { notification } from "antd";
import * as AuthRoutes from "../../../routers/AuthRouter/routes";
import HTMLRender from "../../../components/HTMLRender";
import { OnboardingContext } from "../../../providers/OnboardingProvider";
import { InvalidateContext } from "../../../providers/InvalidateProvider";

const Login: React.FC = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const localeContext = useContext(LocaleContext);
  const { canSkip, resetSkip, canSkipNextTime } = useContext(OnboardingContext);
  const { invalidate } = useContext(InvalidateContext);

  React.useEffect(() => {
    invalidate();
  }, [invalidate]);

  const onSubmit = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    getLogin(email, password)
      .then(({ token }) => {
        const user = getUser(token);

        if (user.skipOnboarding) {
          canSkipNextTime();
        } else {
          resetSkip();
        }
        if (!(user.isRep || user.isManager)) {
          getClientInfo({
            accountCode: user!.clientAccountNumber,
            authToken: token,
          })
            .then(({ data }) => {
              if (!data.clientInfo) {
                notification["error"]({
                  placement: "bottomLeft",
                  message: localeContext.getTranslation(
                    "login_page.error_fetching_client"
                  ),
                });
                return;
              }
              API.setAuthToken!(token);
              history.push(
                canSkip.current
                  ? {
                      pathname: AuthRoutes.default.Home,
                    }
                  : {
                      pathname: AuthRoutes.default.Onboarding,
                      state: {
                        pathname: AuthRoutes.default.Home,
                      },
                    }
              );
              authContext.login(token, { ...user, client: data.clientInfo });
            })
            .catch(() => {
              notification["error"]({
                placement: "bottomLeft",
                message: localeContext.getTranslation(
                  "login_page.error_fetching_client"
                ),
              });
              API.forgetAuthToken!();
            });
        } else {
          history.push({ pathname: ERoutes.SelectClient, state: { token } });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          notification["error"]({
            placement: "bottomLeft",
            message: localeContext.getTranslation("login_page.not_found"),
          });
        } else {
          notification["error"]({
            placement: "bottomLeft",
            message: localeContext.getTranslation("login_page.error_login"),
          });
        }
      });
  };

  return (
    <LocaleConsumer>
      {({ getTranslation }) => (
        <BackgroundImageLayout image={backgroundImage}>
          <div className={Styles["login"]}>
            <h1 className={Styles["login__title"]}>
              {getTranslation("login_page.welcome")}
              <br />
              <span className="title">
                {getTranslation("login_page.app_name")}
              </span>
            </h1>
            <h2>
              <HTMLRender>
                {getTranslation("login_page.description")}
              </HTMLRender>
            </h2>
            <LoginForm onSubmit={onSubmit} />
            <Button
              type="button"
              onClick={() => {
                window.location.href = getTranslation("login_page.url_signup");
              }}
              styleType="outlined"
              size="large"
              label={getTranslation("login_page.signup")}
              className={Styles["signup"]}
            />
            <Button
              type="button"
              onClick={() => {
                window.location.href = getTranslation(
                  "login_page.url_password_forgotten"
                );
              }}
              styleType="text"
              size="large"
              label={getTranslation("login_page.password_forgotten")}
              className={Styles["login__links"]}
            />
            <Button
              type="button"
              link={EGlobalRoutes.PrivacyPolicy}
              styleType="text"
              size="large"
              label={getTranslation("login_page.privacy_policy")}
            />
          </div>
        </BackgroundImageLayout>
      )}
    </LocaleConsumer>
  );
};

export default Login;
